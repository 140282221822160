<template>
  <v-app-bar
    v-scroll="onScroll"

    :hide-on-scroll="$vuetify.breakpoint.smAndUp"

    elevate-on-scroll
    :height="$vuetify.breakpoint.mdAndUp?'200px':'0px'"
    app
  >
    <v-img
      style="z-index: 1000;cursor: pointer;position: absolute"
      alt="Ori Agriculture"
      class="hidden-sm-and-down"
      max-width="250px"
      src="/static/oria-agriculture@white.png"
      onclick="window.location='/'"
    />
    <core-jumbotron v-if="$vuetify.breakpoint.mdAndUp" />
    <template #extension>
      <v-toolbar-items
        v-if="$vuetify.breakpoint.mdAndUp"
        dark
        class="align-center align-content-center align-self-center pa-0 ml-auto mr-auto"
      >
        <v-menu
          v-for="item in items"
          :key="item.text"
          offset-y
          open-on-hover
        >
          <template #activator="{ on }">
            <v-btn

              :class="{ 'v-btn--active': isActive(item.route) }"
              color="primary"
              style="background-color: rgba(0,117,131,1)"
              :to="'/' + $i18n.locale + item.to"

              elevation="0"
              v-on="on"
            >
              <span>{{ $t(item.text ) }} </span>
            </v-btn>
          </template>
          <v-list v-if="item.subItems">
            <v-list-item
              v-for="subItem in item.subItems()"
              :key="subItem.text"
              exact
              :to="'/' + $i18n.locale + subItem.to"
            >
              <v-list-item-title>{{ $t(subItem.text) }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </v-toolbar-items>

      <v-app-bar-nav-icon
        v-else

        class="ml-auto white--text"
        aria-label="Open Navigation Drawer"

        @click="toggleDrawer"
      />

      <button
        v-for="entry in languages"
        :key="entry.title"
        class="hidden-sm-and-down lang-button"
        @click="changeLocale(entry.language)"
      >
        <flag
          :iso="entry.flag"
          :squared="false"
        />
      </button>
    </template>
  </v-app-bar>
</template>

<style>
  .v-toolbar__content{
    padding:0px !important;
  }
  .v-list-item__title {

  }
</style>

<script>
  // Utilities
  import { mapMutations } from 'vuex'
  import i18n from '../../i18n'

  export default {
    components: {

      CoreJumbotron: () => import('@/components/core/Jumbotron'),

    },

    data: () => ({
      user: null,
      isScrolling: false,
      languages: [
        { flag: 'gb', language: 'en', title: 'English' },
        { flag: 'fr', language: 'fr', title: 'Français' },
        { flag: 'de', language: 'de', title: 'Deutsch' },
      ],

    }),
    computed: {

      items () {
        const items = [
          {
            to: '/home',
            text: 'menu.home',
          },
          {
            text: 'menu.about_us',
            to: '/about-us',

            subItems () {
              return [
                { text: 'menu.company_overview', to: '/about-us' },
                { text: 'menu.companies_we_represent', to: '/about-us/companies-we-represent' },

                { text: 'menu.news_articles', to: '/about-us/news' },
              ]
            },
          },

          {
            to: '/products',
            text: 'menu.products',

          },
          {
            to: '/contact',
            text: 'menu.contact',
          },

        ]

        if (this.user != null) {
          items.push(
            {
              text: 'Admin',
              to: '/user/account',

              subItems () {
                return [
                  { text: 'Account', to: '/user/account' },
                ]
              },
            },
          )
        }

        return items
      },
    },
    watch: {
      // call again the method if the route changes
      $route: 'removeActive',

    },
    beforeCreate () {
      var _this = this
      this.$firebaseAuth.onAuthStateChanged(function (user) {
        if (user) {
          _this.user = user
        } else {
          _this.user = null
        }
      })
    },
    methods: {
      changeLocale (locale) {
        i18n.locale = locale
        this.$router.push({ name: this.$router.currentRoute.name, params: { lang: locale } })
      },

      ...mapMutations(['toggleDrawer']),
      onScroll () {
        this.isScrolling = (window.pageYOffset ||
          document.documentElement.scrollTop || 0) > 25
      },
      removeActive () {
        console.log('changed')
      },
      isActive (route) {
        if (this.$router.currentRoute.name === route) {
          return true
        }
        return false
      },

    },
  }
</script>
<style>

  .lang-button {

    margin: 2px;
  }
</style>
